import React from "react";

import styles from "./BottomSectionStyle.module.css";

const BottomSection = ({ data, counter }) => {
  return (
    <div className={styles.bottomSection}>
      <div className={styles.moneyBackContainer}>
        <img
          src="../img/hp-3-moneyBackIMG.png"
          alt="moneyBackIMG"
          className={styles.moneyBackImg}
        />
        <span className={styles.moneyBackText}>{data.title_text}</span>
      </div>
      <div className={styles.containerStatistic}>
        <div className={styles.infoContainer}>
          <span className={styles.topText}>{data.accounts.quantity}</span>
          <span className={styles.mainTextInfo}>
            {data.accounts.accounts_text}
          </span>
        </div>
        <div className={styles.infoContainer}>
          <div className={styles.imgTitleContainer}>
            <img
              src="../img/hp-3-peopleCounterIMG.png"
              alt="peopleCounterIMG"
              className={styles.imgTitle}
            />
            <span className={styles.topText}>{counter}</span>
          </div>
          <span
            className={styles.mainTextInfo}
            style={{
              width: "200px",
            }}
          >
            {data.people_waitlist}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BottomSection;
