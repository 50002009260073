import React, { useState, useEffect } from "react";
import { usePageContent } from "../../../../context/PageContext";
import { preserveUrlParams } from "../../../../utils/urlParams";

import { useRedirectWithReload } from "../../../../hooks/useRedirectWithReload";

import styles from "./HeaderStyle.module.css";

const Header = () => {
  const { pageContent, setDownSaleStatus } = usePageContent();
  const [data] = useState(pageContent.header);
  const [timerValue] = useState(pageContent.timer_value);
  const [timeLeft, setTimeLeft] = useState(timerValue * 60);
  const redirectWithReload = useRedirectWithReload();

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId);
            setDownSaleStatus(false);
            localStorage.setItem("MemoryOSDownSale", "false");
            redirectWithReload(preserveUrlParams("/new/plan"));
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, redirectWithReload]);

  const formatTime = (seconds) => {
    if (seconds <= 0) return "00:00";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <span className={styles.timerTitle}>{data.timer_title}</span>
        <span className={styles.timer}>{formatTime(timeLeft)}</span>
      </div>
    </div>
  );
};

export default Header;
