import React, { useState } from "react";
import { usePageContent } from "../../../../context/PageContext";

import MainButton from "../MainButton/MainButton";

import styles from "./FooterStyle.module.css";

const Footer = () => {
  const { pageContent } = usePageContent();
  const [data] = useState(pageContent.footer);

  const [downSaleStatus] = useState(
    JSON.parse(localStorage.getItem("MemoryOSDownSale"))
  );

  return (
    <div className={styles.container}>
      <span className={styles.missionText}>{data.title_text}</span>
      <MainButton
        btnText={!downSaleStatus ? data.button_text[0] : data.button_text[1]}
        undBtnText={data.under_btn_text}
      />
      <div className={styles.bottomContainer}>
        <div className={styles.hrLine} />
        <div className={styles.compInfoContainer}>
          <a
            href={data.terms[1]}
            className={styles.compInfoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.terms[0]}
          </a>
          <div className={styles.hrVertLine} />
          <a
            href={data.cookie[1]}
            className={styles.compInfoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.cookie[0]}
          </a>
          <div className={styles.hrVertLine} />
          <a
            href={data.privacy[1]}
            className={styles.compInfoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.privacy[0]}
          </a>
        </div>
        <span className={styles.compInfoText}>{data.company}</span>
      </div>
    </div>
  );
};

export default Footer;
