import React from "react";
import MainButton from "../../MainButton/MainButton";

import styles from "./MainSectionStyle.module.css";

const MainSection = ({ data, downSaleStatus }) => {
  return (
    <div className={styles.mainSection}>
      <div className={styles.greenBlock}>
        <span className={styles.greenBlockText}>
          {!downSaleStatus ? data.green_block[0] : data.green_block[1]}
        </span>
      </div>
      {!downSaleStatus ? (
        <div className={styles.mainTextBlock}>
          <div className={styles.mainTopTextSection}>
            <span className={styles.mainCrossedText}>
              {data.main_text[0].crossed_text}
            </span>
            <span className={styles.mainText}>{data.main_text[0].text}</span>
          </div>
          <span className={styles.mainBottomText}>
            {data.main_text[0].bottom_text}
          </span>
        </div>
      ) : (
        <div className={styles.mainTextBlock}>
          <div className={styles.mainTopTextSection}>
            <span className={styles.mainCrossedText}>
              {data.main_text[1].crossed_text}
            </span>
            <span className={styles.mainText}>{data.main_text[1].text}</span>
          </div>
          <span className={styles.mainBottomText}>
            {data.main_text[1].bottom_text}
          </span>
        </div>
      )}
      <div className={styles.buttonContainer}>
        <MainButton
          btnText={data.button_text[0]}
          undBtnText={data.under_btn_text}
        />
      </div>
    </div>
  );
};

export default MainSection;
