import React from "react";

import styles from "../../DetailsContainerStyle.module.css";

const DetailsNewT = ({ orderData }) => {
  return (
    <div className={styles.flowDetailsContainer}>
      <div className={styles.hr} />
      {orderData?.total_price && (
        <div className={styles.element}>
          <span className={styles.totalText}>Total today:</span>
          <span className={styles.totalText}>${orderData.total_price}</span>
        </div>
      )}
      {orderData?.total_price_trial && (
        <div className={styles.element}>
          <span className={styles.totalText}>Total today:</span>
          <span className={styles.totalText}>
            {orderData.total_price_trial[0]}{" "}
            <span className={styles.totalTrialText}>
              {orderData.total_price_trial[1]}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default DetailsNewT;
