import React, { useEffect, useRef, useState } from "react";

import { usePageContent } from "../../../context/PageContext";
import { ButtonRefProvider } from "../../../context/ButtonRefContext";
import { PromoCounterProvider } from "../../../context/PromoIncrementCounerContext";
import { useScroll } from "../../../context/ScrollContext";
import { useEvent } from "../../../context/EventContext";
import { useUrlContext } from "../../../context/urlContext";

import Header from "./Header/Header";
import TopInfo from "./TopInfo/TopInfo";
import MainInfo from "./MainInfo/MainInfo";
import GarantyInfo from "./GarantyInfo/GarantyInfo";
import Footer from "./Footer/Footer";
import PaymentBlock from "./PaymentBlock/PaymentBlock";
import Loader from "../../templates/Loader/Loader";
import LogoHP from "../../templates/LogoHP/LogoHP";

import styles from "./BlackFridayOldThreeDiscountStyle.module.css";

const BlackFridayOldThreeDiscount = () => {
  const { downSaleStatus, setDownSaleStatus } = usePageContent();
  const { analyticsEvent } = useEvent();
  const { holidayPromoThreePath } = useUrlContext();

  useEffect(() => {
    if (!downSaleStatus) {
      setDownSaleStatus(true);
    }
    if (holidayPromoThreePath) {
      analyticsEvent("hp3EssentialPageView");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downSaleStatus]);

  const [isLoading, setIsLoading] = useState(true);
  const { scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent(PageContainerRef);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  const redirectStatus = JSON.parse(localStorage.getItem("MemoryOSredirect"));

  if (redirectStatus) {
    window.location.reload();
    localStorage.setItem("MemoryOSredirect", "false");
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ButtonRefProvider>
      <PromoCounterProvider>
        <div className={styles.bfContainer}>
          <div className={styles.blurContainer} />
          <Header />
          <div
            style={{
              margin: "0 auto",
              marginTop: "30px",
            }}
          >
            <LogoHP />
          </div>
          <TopInfo />
          <MainInfo />
          <GarantyInfo />
          <Footer />
        </div>
        <PaymentBlock />
      </PromoCounterProvider>
    </ButtonRefProvider>
  );
};

export default BlackFridayOldThreeDiscount;
