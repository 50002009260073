import React, { useEffect, useState } from "react";
import { useRemoteConfig } from "../../../context/RemoteConfigContext";
import { usePaymentForm } from "../../../context/PaymentFormContext";
import { useErrorHandler } from "../../../context/ErrorHandlerContext";
import { useDevice } from "../../../context/DeviceCheckContext";

import { useConsoleLog } from "../../../hooks/consoleLog";

import styles from "./PaymentButtonsStyle.module.css";

const PaymentButtons = ({
  paypalBtnRef,
  googleContainerRef,
  appleContainerRef,
  setFormLoading,
}) => {
  const { appData } = useRemoteConfig();

  const { scriptUrl, startOrderStatusCheck } = usePaymentForm();

  const { setErrorHandler } = useErrorHandler();

  const { isSafari, isMac, isIOS } = useDevice();

  const logger = useConsoleLog();

  const [applePayVisible, setApplePayVisible] = useState(false);

  useEffect(() => {
    logger.warn(`isSafari:`, isSafari);
    logger.warn(`isMac:`, isMac);
    logger.warn(`isIOS:`, isIOS);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isMac) {
      if (isSafari) {
        setApplePayVisible(true);
      } else {
        setApplePayVisible(false);
      }
    } else if (isIOS) {
      setApplePayVisible(true);
    } else {
      setApplePayVisible(false);
    }
  }, [isSafari, isMac, isIOS]);

  useEffect(() => {
    if (scriptUrl && paypalBtnRef.current) {
      paypalBtnRef.current.addEventListener(
        "order-started-processing",
        function (e) {
          console.log("order-started-processing", e);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-processed",
        function (e) {
          console.log("order-processed", e);
          startOrderStatusCheck();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-already-processed",
        function (e) {
          console.log("order-already-processed", e);
          startOrderStatusCheck();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-ready",
        function (e) {
          console.log("button-ready", e);
          setFormLoading(false);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-error",
        function (e) {
          console.log("button-error", e);
          setErrorHandler(e);
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptUrl]);

  // const handleApplePayClick = () => {
  //   setTimeout(() => {
  //     if (appleContainerRef.current) {
  //       const applePayButton =
  //         appleContainerRef.current.querySelector("apple-pay-button");
  //       if (applePayButton) {
  //         applePayButton.click();
  //       } else {
  //         appleContainerRef.current.click();
  //       }
  //     }
  //   }, 100);
  // };

  return (
    <div className={styles.buttonsContainer}>
      <span className={styles.payFastText}>Pay fast & secure with</span>
      {/* enable this code new apple pay button */}
      {/* <div className={styles.applePayButtonContainer}>
              <div
                className={styles.applePayCopyButton}
                onClick={handleApplePayClick}
              >
                <img
                  src="../img/apple-white-logo.png"
                  alt="apple pay"
                  className={styles.applePayLogo}
                />
                <span className={styles.applePayText}>Pay</span>
              </div>
              <div className={styles.appleContainerBtn} ref={appleContainerRef} />
            </div> */}
      {/* end enable */}

      {/* disable this code */}
      {/* end */}
      <div
        className={styles.appleContainerBtn}
        ref={appleContainerRef}
        style={{ display: applePayVisible ? "block" : "none" }}
      />

      <div className={styles.googleContainerBtn} ref={googleContainerRef} />
      <div className={styles.googlePayPalContainer}>
        {parseInt(appData.REACT_APP_IS_PAYPAL_ORDER) && scriptUrl && (
          <div
            id="paypal-button"
            ref={paypalBtnRef}
            style={{ marginTop: "10px" }}
            className={styles.payPalButton}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentButtons;
