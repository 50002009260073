import React, { useEffect, useMemo } from "react";
import ReactDOM from "react-dom/client";
import * as amplitude from "@amplitude/analytics-browser";
import "./index.css";
import { ScrollProvider } from "./context/ScrollContext";
import { FirebaseConfigProvider } from "./context/RemoteConfigContext";
import { EventProvider } from "./context/EventContext";
import { UrlProvider } from "./context/urlContext";
import { ErrorHandlerProvider } from "./context/ErrorHandlerContext";
import { PaymentProvider } from "./context/PaymentBlockContext";
import { ButtonRefProvider } from "./context/ButtonRefContext";
import { EmailValidatorProvider } from "./context/EmailValidatorContext";
import { DeviceProvider } from "./context/DeviceCheckContext";
import { DevModeProvider } from "./context/DevMode";
import { PaymentFormProvider } from "./context/PaymentFormContext";
import { EnvProvider } from "./context/EnvironmentContext";
import { AnalyticsUserDataProvider } from "./context/AnalyticsUserData";
import { AmplitudeProvider } from "./context/AmplitudeContext";
import { RouteHandlerProvider } from "./context/RouteHandlerContext";
import { PageProvider } from "./context/PageContext";
import { PageConfigProvider } from "./context/PageConfigContext";

import App from "./App";
import { EventFilterProvider } from "./context/EventFilter";
// import { VWOprovider } from "./context/InitVWOcontext";

amplitude.init("ee6a72438081ce5583b67e41c270dbd", {
  defaultTracking: true,
  autocapture: {
    attribution: true,
    sessions: true,
    formInteractions: true,
  },
});

const Index = () => {
  const environment = useMemo(() => {
    const currentURL = window.location.href;
    const productionDomain = "start.memoryos.com";
    const stagingDomain = "staging.start.memoryos.com";

    if (
      currentURL.includes(productionDomain) &&
      !currentURL.includes(stagingDomain)
    ) {
      return "production";
    } else if (
      currentURL.includes("localhost") ||
      currentURL.includes("127.0.0.1")
    ) {
      return "development";
    } else {
      return "staging";
    }
  }, []);

  useEffect(() => {
    if (environment === "staging") {
      import("eruda").then((lib) => lib.default.init());
    }
  }, [environment]);

  return (
    <RouteHandlerProvider>
      <ErrorHandlerProvider>
        <EnvProvider>
          <DeviceProvider>
            <UrlProvider>
              <DevModeProvider>
                {/* <VWOprovider> */}
                <FirebaseConfigProvider>
                  <PageConfigProvider>
                    <ScrollProvider>
                      <EventFilterProvider>
                        <EventProvider>
                          <ButtonRefProvider>
                            <PaymentProvider>
                              <EmailValidatorProvider>
                                <PaymentFormProvider>
                                  <AnalyticsUserDataProvider>
                                    <AmplitudeProvider>
                                      <PageProvider>
                                        <App />
                                      </PageProvider>
                                    </AmplitudeProvider>
                                  </AnalyticsUserDataProvider>
                                </PaymentFormProvider>
                              </EmailValidatorProvider>
                            </PaymentProvider>
                          </ButtonRefProvider>
                        </EventProvider>
                      </EventFilterProvider>
                    </ScrollProvider>
                  </PageConfigProvider>
                </FirebaseConfigProvider>
                {/* </VWOprovider> */}
              </DevModeProvider>
            </UrlProvider>
          </DeviceProvider>
        </EnvProvider>
      </ErrorHandlerProvider>
    </RouteHandlerProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
