import React, { useRef, useState } from "react";

import { useResizeContainer } from "../../../../hooks/resizeContainer";
import { useGenerateHPDate } from "../../../../hooks/generateHPDate";

import { useUrlContext } from "../../../../context/urlContext";
import { usePageContent } from "../../../../context/PageContext";

import LogoHP from "../../../templates/LogoHP/LogoHP";
import YouTubePlayer from "../../../templates/YoutubePlayer/YoutubePlayer";
import MainButton from "../MainButton/MainButton";

import styles from "./MainInfoStyle.module.css";

const MainInfo = () => {
  const { pageContent, downSaleStatus } = usePageContent();

  const { holidayOldPromoPathDuplicateTwo, holidayOldPromoPathDuplicateThree } =
    useUrlContext();

  const [data] = useState(pageContent.main_info);

  const containerRef = useRef(null);

  const { widthCont, heightCont, radiusCont } =
    useResizeContainer(containerRef);

  const generatedHPdate = useGenerateHPDate();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.headerMainText}>{data.header.main_text}</span>
        {!downSaleStatus && (
          <span className={styles.headerBottomText}>
            {data.header.bottom_text[0]}
          </span>
        )}
        {downSaleStatus && (
          <span className={styles.headerBottomText}>
            {data.header.bottom_text[1]}
          </span>
        )}
      </div>
      <div className={styles.videoContainer} ref={containerRef}>
        <YouTubePlayer
          videoId={data.youtube_video_id}
          width={widthCont}
          height={heightCont}
          borderRadius={radiusCont}
        />
      </div>
      <div className={styles.mainContent}>
        <LogoHP styleData={{ width: "151px", height: "45px" }} />
        <div className={styles.partTextContainer}>
          {data.main_content.text_parts.map((text, index) => {
            return (
              <div key={index} className={styles.mainPartsText}>
                {index === 1 ? (!downSaleStatus ? text[0] : text[1]) : text}
              </div>
            );
          })}
        </div>
        <div className={styles.buttonContainer}>
          <MainButton
            width={"284px"}
            height={"52px"}
            btnText={data.main_content.button_text}
          />
        </div>

        {!downSaleStatus && (
          <span className={styles.footerText}>
            {data.footer_text[0]}
            {(holidayOldPromoPathDuplicateTwo ||
              holidayOldPromoPathDuplicateThree) &&
              ` ${generatedHPdate}`}
          </span>
        )}
        {downSaleStatus && (
          <span className={styles.footerText}>
            {data.footer_text[1].map((text, index) => (
              <React.Fragment key={index}>
                {index === 1 || index === 3 ? (
                  <span className={styles.footerTextSpecial}>{text}</span>
                ) : (
                  text
                )}{" "}
              </React.Fragment>
            ))}
            {(holidayOldPromoPathDuplicateTwo ||
              holidayOldPromoPathDuplicateThree) &&
              ` ${generatedHPdate}`}
          </span>
        )}
      </div>
    </div>
  );
};

export default MainInfo;
