import React, { useEffect, useRef, useState } from "react";

import { usePageContent } from "../../../context/PageContext";
import { ButtonRefProvider } from "../../../context/ButtonRefContext";
import { PromoCounterProvider } from "../../../context/PromoIncrementCounerContext";
import { useScroll } from "../../../context/ScrollContext";
import { useUrlContext } from "../../../context/urlContext";
import { useEvent } from "../../../context/EventContext";

import Header from "./Header/Header";
import TopInfo from "./TopInfo/TopInfo";
import MainInfo from "./MainInfo/MainInfo";
import GarantyInfo from "./GarantyInfo/GarantyInfo";
import Footer from "./Footer/Footer";
import PaymentBlock from "./PaymentBlock/PaymentBlock";
import DownSalePopup from "./DownSalePopup/DownSalePopup";
import Loader from "../../templates/Loader/Loader";

import styles from "./BlackFridayOldThreeStyles.module.css";
import LogoHP from "../../templates/LogoHP/LogoHP";

const BlackFridayOldThree = () => {
  const { showHideDownSalePopup, downSaleStatus, setDownSaleStatus } =
    usePageContent();
  const { analyticsEvent } = useEvent();

  const { holidayPromoThreePath } = useUrlContext();

  useEffect(() => {
    if (downSaleStatus) {
      setDownSaleStatus(false);
    }
    if (holidayPromoThreePath) {
      analyticsEvent("hp3ExpertPageView");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectStatus = JSON.parse(localStorage.getItem("MemoryOSredirect"));

  if (redirectStatus) {
    window.location.reload();
    localStorage.setItem("MemoryOSredirect", "false");
  }

  const [isLoading, setIsLoading] = useState(true);
  const { scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent(PageContainerRef);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ButtonRefProvider>
      <PromoCounterProvider>
        <div className={styles.bfContainer} ref={PageContainerRef}>
          <div className={styles.blurContainer} />
          <Header />
          <div
            style={{
              margin: "0 auto",
              marginTop: "30px",
            }}
          >
            <LogoHP />
          </div>
          <TopInfo />
          <MainInfo />
          <GarantyInfo />
          <Footer />
        </div>
        <PaymentBlock />
        {showHideDownSalePopup && <DownSalePopup />}
      </PromoCounterProvider>
    </ButtonRefProvider>
  );
};

export default BlackFridayOldThree;
