import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useTimer } from "../../../context/TimerContext";
import { usePageContent } from "../../../context/PageContext";
import { usePaymentBlock } from "../../../context/PaymentBlockContext";
import { usePageConfig } from "../../../context/PageConfigContext";

import { useNavigationPath } from "../../../hooks/navigationPath";
import { preserveUrlParams } from "../../../utils/urlParams";

import styles from "./PaymentHeaderStyle.module.css";

const PaymentHeader = () => {
  const { timer } = useTimer();
  const { downSaleStatus, setShowHideDownSalePopup, activateDiscount } =
    usePageContent();
  const { setStartPaymentForm } = usePaymentBlock();
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("PaymentForm");

  const navigate = useNavigate();
  const previosPath = useNavigationPath("plan");

  const closePaymentForm = () => {
    if (timer > 0) {
      if (downSaleStatus !== true) {
        if (activateDiscount) {
          setShowHideDownSalePopup(true);
          setStartPaymentForm(false);
          navigate(preserveUrlParams(previosPath));
        }
        setStartPaymentForm(false);
        navigate(preserveUrlParams(previosPath));
      }
      setStartPaymentForm(false);
      navigate(preserveUrlParams(previosPath));
    }
    setStartPaymentForm(false);
    navigate(preserveUrlParams(previosPath));
  };

  useEffect(() => {
    const handleBackButton = (e) => {
      e.preventDefault();
      closePaymentForm();
    };

    window.addEventListener("popstate", handleBackButton);
    window.addEventListener("pageshow", (event) => {
      if (event.persisted) {
        handleBackButton(event);
      }
    });

    return () => {
      window.removeEventListener("popstate", handleBackButton);
      window.removeEventListener("pageshow", handleBackButton);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, downSaleStatus, activateDiscount]);

  return (
    <div className={styles.headerContainer}>
      <span className={styles.headerText}>{data.headerText}</span>
      <button
        className={styles.closePaymentFormButton}
        onClick={() => {
          closePaymentForm();
        }}
      >
        <img
          src="../img/payment_form_back_button.png"
          alt="paymentFormCloseButton"
          className={styles.imgCloseButton}
        />
      </button>
    </div>
  );
};

export default PaymentHeader;
