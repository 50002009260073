import React from "react";
import { usePageContent } from "../../../../../../context/PageContext";

import Price from "../Price/Price";

import styles from "./OptionsStyle.module.css";

const RenderOptions = () => {
  const {
    activeProductIndex,
    setActiveProductIndex,
    toggleStatus,
    renderProducts,
    productProcessing,
    pageContent,
  } = usePageContent();

  const handleOptionChange = (index) => {
    setActiveProductIndex(index);
    productProcessing(index);
  };

  return (
    <>
      {renderProducts.map((product, index) => (
        <label
          key={index}
          htmlFor={product.product_id}
          className={`${styles.elementContainer} ${
            index === activeProductIndex ? styles.elementContainerActive : ""
          }`}
          style={{
            height: product.most_popular ? "112px" : "100px",
          }}
        >
          {product.most_popular && (
            <div
              className={`${styles.mostPopular} ${
                index === activeProductIndex ? styles.mostPopularActive : ""
              }`}
            >
              <span className={styles.mostPopularText}>
                {pageContent.most_popular_text}
              </span>
            </div>
          )}
          <div className={styles.optionContainer}>
            <input
              type="radio"
              name={product.plan_name}
              id={product.product_id}
              value={product.full_price}
              checked={index === activeProductIndex}
              onChange={() => handleOptionChange(index)}
              style={{
                display: "none",
              }}
            />
            <div className={styles.optionHeaderContainer}>
              <span className={styles.optionHeaderText}>
                {index === 0
                  ? !toggleStatus
                    ? product.selection_option_title
                    : product.trial_selection_option_title
                  : product.selection_option_title}
              </span>
            </div>

            <div className={styles.saveTextParts}>
              {!toggleStatus
                ? product.option_main_text.map((text, index) => (
                    <span
                      className={styles.partText}
                      key={index}
                      style={{
                        textDecoration: text.cross_out_text
                          ? "line-through"
                          : "none",
                        color: text.text_color,
                      }}
                    >
                      {text.text}
                    </span>
                  ))
                : product.trial_option_main_text
                ? product.trial_option_main_text.map((text, index) => (
                    <span
                      className={styles.partText}
                      key={index}
                      style={{
                        textDecoration: text.cross_out_text
                          ? "line-through"
                          : "none",
                        color: text.text_color,
                      }}
                    >
                      {text.text}
                    </span>
                  ))
                : product.option_main_text.map((text, index) => (
                    <span
                      className={styles.partText}
                      key={index}
                      style={{
                        textDecoration: text.cross_out_text
                          ? "line-through"
                          : "none",
                        color: text.text_color,
                      }}
                    >
                      {text.text}
                    </span>
                  ))}
            </div>

            {product.option_save_text_block.show_hide_block && (
              <div
                className={styles.blockSaveText}
                style={{
                  width: product.option_save_text_block.block_width,
                  height: product.option_save_text_block.block_height,
                  backgroundColor:
                    index === activeProductIndex
                      ? product.option_save_text_block.active_background_color
                      : product.option_save_text_block
                          .not_active_background_color,
                }}
              >
                <span
                  className={styles.partTextGreen}
                  style={{
                    color:
                      index === activeProductIndex
                        ? product.option_save_text_block.active_text_color
                        : product.option_save_text_block.not_active_text_color,
                  }}
                >
                  {product.option_save_text_block.text}
                </span>
              </div>
            )}
            {toggleStatus && activeProductIndex === index && (
              <div className={styles.trialNotifBlock}>
                <span className={styles.trialNotifText}>
                  {product.trial_enabled_text}
                </span>
              </div>
            )}
          </div>

          <Price
            pricePerDay={product.price_per_day}
            oldPrice={product?.cross_price_per_day}
          />
        </label>
      ))}
    </>
  );
};

export default RenderOptions;
