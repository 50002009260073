import { useEnvContext } from "../context/EnvironmentContext";

export const useConsoleLog = () => {
  const { isProduction } = useEnvContext();

  return {
    log: (...args) => {
      if (!isProduction) console.log(...args);
    },
    warn: (...args) => {
      if (!isProduction) console.warn(...args);
    },
    error: (...args) => {
      if (!isProduction) console.error(...args);
    },
    critical: (...args) => {
      console.error("[CRITICAL]", ...args);
    },
  };
};
