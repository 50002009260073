import React, { useEffect, useState } from "react";

import styles from "../TopComponent/TopComponentStyle.module.css";
import { usePageContent } from "../../../../context/PageContext";

const TopComponentVariantB = ({ userName, dataComponent, planText }) => {
  const { renewalDate } = usePageContent();

  const [currentPath] = useState(window.location.href);

  const [showHideRenewalDate, setShowHideRenewalDate] = useState(false);

  useEffect(() => {
    const targetPath = currentPath.includes("/new-t/thankyou");
    if (targetPath) {
      setShowHideRenewalDate(true);
    }
  }, [currentPath]);

  return (
    <div className={styles.container}>
      <div className={styles.welcomeUserText}>
        <span className={styles.userNameText}>{userName}</span>
        <span>{dataComponent.welcomeText}</span>
      </div>
      <div className={styles.subscContainer}>
        <span className={styles.subscContainerText}>
          {dataComponent.subscText}
        </span>
        <span className={styles.subscContPlanText}>{planText}</span>
        {showHideRenewalDate && renewalDate && (
          <span className={styles.subscContExpText}>
            Renewal: {renewalDate}
          </span>
        )}
      </div>
    </div>
  );
};

export default TopComponentVariantB;
