import React from "react";
import styles from "./HeadSectionStyle.module.css";

const HeadSection = ({ data }) => {
  return (
    <div className={styles.headSection}>
      <div className={styles.topTag}>
        <span className={styles.topTagText}>{data.header_top_container}</span>
        <img
          src="../img/fire_hp3.png"
          alt="fire_hp3"
          className={styles.tagImg}
        />
      </div>
      <span className={styles.headerText}>
        {data.header}
        <img
          src="../img/gift_hp3.png"
          alt="gift_hp3"
          className={styles.headerImg}
        />
      </span>
    </div>
  );
};

export default HeadSection;
