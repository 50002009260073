import React, { useEffect } from "react";

import { useButtonRef } from "../../context/ButtonRefContext";
import { usePaymentBlock } from "../../context/PaymentBlockContext";

import { useNavigateToPaymentForm } from "../../hooks/navigateToPaymentForm";

import UserNameEmailStep from "./UserNameEmailStep/UserNameEmailStep";
import HasActiveSubscModal from "./HasActiveSubscModal/HasActiveSubscModal";

import LoaderIcon from "../Plan/loaderIcon";
import { Loader } from "../Plan/styles";

import styles from "./PaymentBlock.module.css";

const PaymentBlock = () => {
  const {
    loader,
    startNameEmailForm,
    startPaymentForm,
    startActiveSubscForm,
    loadingForm,
  } = usePaymentBlock();

  const { paymentButtonRef } = useButtonRef();

  const navigateToPaymentForm = useNavigateToPaymentForm();

  useEffect(() => {
    if (startPaymentForm) {
      navigateToPaymentForm();
    }
  }, [startPaymentForm, navigateToPaymentForm]);

  return (
    <div className={styles.PaymentFormContainer}>
      <button
        ref={paymentButtonRef}
        onClick={async () => {
          loadingForm();
        }}
        style={{
          display: "none",
        }}
      />
      {startNameEmailForm && <UserNameEmailStep />}
      {startActiveSubscForm && <HasActiveSubscModal />}

      {loader ? (
        <Loader>
          <LoaderIcon />
        </Loader>
      ) : null}
    </div>
  );
};

export default PaymentBlock;
