import React, { createContext, useContext, useEffect, useState } from "react";
import { useUrlContext } from "./urlContext";

const EventFilterContext = createContext();

export const useEventFilter = () => useContext(EventFilterContext);

export const EventFilterProvider = ({ children }) => {
  const { queryParams } = useUrlContext();

  const [snaptrEventStatus, setSneptrEventStatus] = useState(false);

  console.warn(`snaptr event status:`, snaptrEventStatus);

  useEffect(() => {
    if (queryParams.ScCid || queryParams.sccid || queryParams.scid) {
      setSneptrEventStatus(true);
    }
  }, [queryParams]);

  const contextValue = {
    snaptrEventStatus,
  };

  return (
    <EventFilterContext.Provider value={contextValue}>
      {children}
    </EventFilterContext.Provider>
  );
};
