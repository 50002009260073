import React, { useEffect, useRef, useState } from "react";
import { usePageConfig } from "../../../context/PageConfigContext";
import { usePageContent } from "../../../context/PageContext";
import { ButtonRefProvider } from "../../../context/ButtonRefContext";
import { PromoCounterProvider } from "../../../context/PromoIncrementCounerContext";
import { useScroll } from "../../../context/ScrollContext";
import { useEvent } from "../../../context/EventContext";
import { useUrlContext } from "../../../context/urlContext";

import Header from "./Header/Header";
import TopInfo from "./TopInfo/TopInfo";
import MainInfo from "./MainInfo/MainInfo";
import GarantyInfo from "./GarantyInfo/GarantyInfo";
import Footer from "./Footer/Footer";
import PaymentBlock from "./PaymentBlock/PaymentBlock";
import Loader from "../../templates/Loader/Loader";

import styles from "./BlackFridayOldDiscountStyle.module.css";

const BlackFridayOldDiscount = () => {
  const { downSaleStatus, setDownSaleStatus } = usePageContent();
  const { findDataComponent } = usePageConfig();
  const { analyticsEvent } = useEvent();
  const {
    holidayOldPromoPath,
    holidayOldPromoPathDuplicate,
    holidayOldPromoPathDuplicateTwo,
    holidayOldPromoPathDuplicateThree,
  } = useUrlContext();

  const data = findDataComponent("BlackFridayOld");

  useEffect(() => {
    if (!downSaleStatus) {
      setDownSaleStatus(true);
    }
    if (holidayOldPromoPath) {
      analyticsEvent("hp23EssentialPageView");
    } else if (holidayOldPromoPathDuplicate) {
      analyticsEvent("hp23dEssentialPageView");
    } else if (holidayOldPromoPathDuplicateTwo) {
      analyticsEvent("hp23d2EssentialPageView");
    } else if (holidayOldPromoPathDuplicateThree) {
      analyticsEvent("hp23d3EssentialPageView");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downSaleStatus]);

  const [isLoading, setIsLoading] = useState(true);
  const { scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent(PageContainerRef);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <ButtonRefProvider>
      <PromoCounterProvider>
        <div className={styles.bfContainer}>
          <Header />
          <TopInfo />
          <MainInfo />
          <GarantyInfo />
          <Footer data={data.Footer} />
        </div>
        <PaymentBlock />
      </PromoCounterProvider>
    </ButtonRefProvider>
  );
};

export default BlackFridayOldDiscount;
