import React from "react";

import styles from "../../DetailsContainerStyle.module.css";

const DetailsNewH = ({ data, toggleStatus, orderData }) => {
  if (!data?.plan_name || !data?.discount_text) {
    return null;
  }
  return (
    <div className={styles.flowDetailsContainer}>
      <div className={styles.element}>
        <span className={styles.regularText}>{data.plan_name?.title}</span>
        <span
          className={styles.regularText}
          style={{ textDecoration: "line-through" }}
        >
          {data.plan_name?.cross_price}
        </span>
      </div>
      <div className={styles.element}>
        <span className={styles.regularText} style={{ color: "#FF5858" }}>
          {data.discount_text?.title}
        </span>
        <span className={styles.regularText} style={{ color: "#FF5858" }}>
          {data.discount_text?.discount_price}
        </span>
      </div>
      {toggleStatus && data.due_on && (
        <div className={styles.element}>
          <span className={styles.regularText}>{data.due_on.title}</span>
          <span className={styles.regularText}>{data.due_on.price}</span>
        </div>
      )}

      <div className={styles.hr} />
      {orderData?.total_price && (
        <div className={styles.element}>
          <span className={styles.totalText}>Total today:</span>
          <span className={styles.totalText}>${orderData.total_price}</span>
        </div>
      )}
      {orderData?.total_price_trial && (
        <div className={styles.element}>
          <span className={styles.totalText}>Total today:</span>
          <span className={styles.totalText}>
            {orderData.total_price_trial[0]}{" "}
            <span className={styles.totalTrialText}>
              {orderData.total_price_trial[1]}
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default DetailsNewH;
