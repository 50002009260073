import React, { createContext, useContext } from "react";

const RouteHandlerContext = createContext();

export const useRouteHandler = () => useContext(RouteHandlerContext);

export const RouteHandlerProvider = ({ children }) => {
  return (
    <RouteHandlerContext.Provider value={{}}>
      {children}
    </RouteHandlerContext.Provider>
  );
};

export const useRouteCorrection = () => {
  const correctRoutes = [
    "/new",
    "/new/plan",
    "/new/quiz",
    "/new/thankyou",
    "/b",
    "/c",
    "/quiz",
    "/plan",
    "/apply-discount",
    "/thankyou",
    "/reserve",
    "/lt-access",
    "/",
    "/dev/test/test_component",
    "/hp",
    "/hp/quiz",
    "/hp/waitlist",
    "/hp/reserve",
    "/hp/reserve-essential",
    "/hp/thankyou",
    "/hp-23",
    "/hp-23/quiz",
    "/hp-23/waitlist",
    "/hp-23/reserve",
    "/hp-23/reserve-essential",
    "/hp-23/thankyou",
    "/hp-23d",
    "/hp-23d/quiz",
    "/hp-23d/waitlist",
    "/hp-23d/reserve",
    "/hp-23d/reserve-essential",
    "/hp-23d/thankyou",
    "/hp-23d2",
    "/hp-23d2/quiz",
    "/hp-23d2/waitlist",
    "/hp-23d2/reserve",
    "/hp-23d2/reserve-essential",
    "/hp-23d2/thankyou",
    "/hp-23d3",
    "/hp-23d3/quiz",
    "/hp-23d3/waitlist",
    "/hp-23d3/reserve",
    "/hp-23d3/reserve-essential",
    "/hp-23d3/thankyou",
    "/hp-3",
    "/hp-3/quiz",
    "/hp-3/waitlist",
    "/hp-3/reserve",
    "/hp-3/reserve-essential",
    "/hp-3/thankyou",
    "/new-h",
    "/new-h/plan",
    "/new-h/payment",
    "/new-h/quiz",
    "/new-h/thankyou",
    "/new-t",
    "/new-t/plan",
    "/new-t/payment",
    "/new-t/quiz",
    "/new-t/thankyou",
  ];

  const correctRoute = (path) => {
    if (path.includes("?")) {
      return null;
    }

    const sortedRoutes = [...correctRoutes].sort((a, b) => b.length - a.length);

    for (const route of sortedRoutes) {
      if (path.startsWith(route)) {
        if (path === route) {
          return null;
        }

        const nextSegment = path.slice(route.length).split("/")[1];
        if (nextSegment) {
          return null;
        }

        return route;
      }
    }

    return "/new";
  };

  return { correctRoute };
};
