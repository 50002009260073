import { useState, useEffect } from "react";

export const useGenerateHPDate = () => {
  const [hpDate, setHpDate] = useState("");

  useEffect(() => {
    const generateDate = () => {
      const currentDate = new Date();

      const futureDate = new Date(currentDate.getTime() + 72 * 60 * 60 * 1000);

      const month = (futureDate.getMonth() + 1).toString().padStart(2, "0");
      const day = futureDate.getDate().toString().padStart(2, "0");
      const year = futureDate.getFullYear();

      return `${month}/${day}/${year}`;
    };

    setHpDate(generateDate());
  }, []);

  return hpDate;
};
