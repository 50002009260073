import { useNavigate } from "react-router-dom";

export const useRedirectWithReload = () => {
  const navigate = useNavigate();

  const redirectWithReload = (path) => {
    // navigate(path, { replace: true });
    navigate(path);
    localStorage.setItem("MemoryOSredirect", "true");
    window.location.reload();
  };

  return redirectWithReload;
};
