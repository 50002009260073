import React from "react";
import { usePageContent } from "../../../context/PageContext";

import styles from "../PaymentFormStyle.module.css";

const BottomDetails = () => {
  const { productOrder, toggleStatus } = usePageContent();

  return (
    <div className={styles.bottomDetailsContainer}>
      <div className={styles.textContainer}>
        {!toggleStatus ? (
          <span className={styles.textWithTrial}>
            {productOrder.payment_form_text}
          </span>
        ) : (
          <span className={styles.textWithoutTrial}>
            {productOrder.trial_payment_form_text}
          </span>
        )}
      </div>
    </div>
  );
};

export default BottomDetails;
