import React, { useState } from "react";
import { usePageContent } from "../../../../context/PageContext";
import { usePromoCounter } from "../../../../context/PromoIncrementCounerContext";

import HeadSection from "./HeadSection/HeadSection";
import MainSection from "./MainSection/MainSection";
import BottomSection from "./BottomSection/BottomSection";

import styles from "./TopInfoStyle.module.css";

const TopInfo = () => {
  const { pageContent, downSaleStatus } = usePageContent();

  const { counter } = usePromoCounter();

  const [data] = useState(pageContent.top_info);

  return (
    <div className={styles.container}>
      <HeadSection data={data.head_section} />
      <MainSection data={data.main_section} downSaleStatus={downSaleStatus} />
      <BottomSection data={data.bottom_section} counter={counter} />
    </div>
  );
};

export default TopInfo;
