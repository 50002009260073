import React, { createContext, useContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";

import { useEnvContext } from "./EnvironmentContext";
import { useDevMode } from "./DevMode";
import { useUrlContext } from "./urlContext";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from "firebase/remote-config";
import Loader from "../pages/templates/Loader/Loader";

const RemoteConfigContext = createContext();
export const useRemoteConfig = () => useContext(RemoteConfigContext);

export const FirebaseConfigProvider = ({ children }) => {
  const { isProduction } = useEnvContext();
  const {
    defaultPagePath,
    applyDiscountPage,
    holidayPromoPath,
    holidayOldPromoPath,
    holidayOldPromoPathDuplicate,
    holidayOldPromoPathDuplicateTwo,
    holidayOldPromoPathDuplicateThree,
    holidayPromoThreePath,
    duplicatePlanPagePath,
    duplicatePlanPagePathABtest,
  } = useUrlContext();
  const { devModeVariant } = useDevMode();

  const [appData, setAppData] = useState({});
  const [quizData, setQuiz] = useState({});
  const [feedbackData, setFeedbackData] = useState({});

  const [componentsData, setComponentsData] = useState({});
  const [waitlistPageData, setWaitListPageData] = useState({});
  const [reservePageData, setReservePageData] = useState({});
  const [peopleOnWaitlist, setPeopleOnWaitlist] = useState("");
  const [newPageReviews, setNewPageReviews] = useState([]);
  const [newPageVideoReviews, setVideoReviews] = useState([]);
  const [fbEvent, setFbEvent] = useState(false);
  const [paymentButtons, setPaymentButtons] = useState({});
  const [optionTextData, setOptionTextData] = useState({});
  const [apiGeoUrl, setApiGeoUrl] = useState("");

  const [redirectUrl, setRedirectUrl] = useState("");
  const [emailProviders, setEmailProviders] = useState({});

  const [abTestCampaignName, setABtestCampaignName] = useState(null);
  const [abTestStartCampaignDate, setABtestStartCampaignDate] = useState(null);

  const [emailsToDisableAnalyticsEvent, setEmailsToDisableAnalyticsEvent] =
    useState([]);

  const [isConfigReady, setIsConfigReady] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyDDEVWxCxP8u_hvobFFusUHuOgkDuGCk6s",
    authDomain: "memoryos-web-funnel.firebaseapp.com",
    projectId: "memoryos-web-funnel",
    storageBucket: "memoryos-web-funnel.appspot.com",
    messagingSenderId: "1056744550522",
    appId: "1:1056744550522:web:f51c7114e8af84f7760bc3",
    measurementId: "G-QWT83PZ7FZ",
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  firebase.initializeApp(firebaseConfig);
  const analyticsFirebase = firebase.analytics();

  remoteConfig.settings.minimumFetchIntervalMillis = 100;

  const [remotePageContent, setPageContent] = useState({});

  const [abTestVariant, setAbTestVariant] = useState();
  const [currentABvariant, setCurrentAbVariant] = useState(null);

  useEffect(() => {
    const MAX_CHECKS = 15;
    let checkCount = 0;

    const checkForStoredVariant = () => {
      const storedVariant = localStorage.getItem("MemoryOSVWO");
      if (storedVariant) {
        return JSON.parse(storedVariant);
      }
      return null;
    };

    const checkAndSetVariant = () => {
      const variant = checkForStoredVariant();
      if (variant !== null) {
        setAbTestVariant(variant);
        return true;
      }
      return false;
    };

    if (!checkAndSetVariant()) {
      const intervalId = setInterval(() => {
        checkCount++;
        if (checkAndSetVariant() || checkCount >= MAX_CHECKS) {
          clearInterval(intervalId);
          if (checkCount >= MAX_CHECKS) {
            console.log(
              "Max checks reached. Setting abTestVariant to default."
            );
            setAbTestVariant(null);
          }
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (abTestVariant || abTestVariant === null) {
      setData(remoteConfig, abTestVariant, devModeVariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteConfig, devModeVariant, abTestVariant]);

  const [hideDevMode, setHideDevMode] = useState(false);

  const setData = (remoteConfig, abTestVariant, devModeVariant) => {
    setCurrentAbVariant(abTestVariant);
    if (!abTestVariant) {
      console.error("Default variant: enabled");
      setHideDevMode(true);
    } else {
      console.info("Default variant: disabled");
    }

    const loadPageConfig = (data, pagePath, isProduction) => {
      const env = isProduction ? "production" : "staging";
      return JSON.parse(data[`${env}_${pagePath}`]._value);
    };

    const handleWaitlistData = (pageConfig, waitlistConfig) => {
      setWaitListPageData(waitlistConfig.waitlist_page);
      setReservePageData(pageConfig.page_content?.reserve_page);
      if (pageConfig.page_content?.reserve_page?.people_on_waitlist) {
        setPeopleOnWaitlist(
          pageConfig.page_content.reserve_page.people_on_waitlist
        );
      }
    };

    const needsWaitlistData = (pagePath) => {
      const pagesWithWaitlist = [
        "black_friday_page",
        "old_black_friday_page",
        "old_black_friday_page_duplicate",
        "old_black_friday_page_duplicate_two",
        "old_black_friday_page_duplicate_three",
        "pre_christmas_page_three",
      ];
      return pagesWithWaitlist.includes(pagePath);
    };

    const getABTestConfig = (
      data,
      isProduction,
      abTestVariant,
      devModeVariant,
      defaultPageName
    ) => {
      if (!abTestVariant && devModeVariant === null) {
        return loadPageConfig(data, defaultPageName, isProduction);
      }
      if (defaultPagePath || duplicatePlanPagePathABtest) {
        const prefix = defaultPagePath ? "new_variant_" : "variant_";

        if (typeof abTestVariant === "number" || devModeVariant !== null) {
          const variant =
            devModeVariant !== null ? devModeVariant : abTestVariant;
          const useProduction =
            typeof abTestVariant === "number" && devModeVariant === null;

          return loadPageConfig(
            data,
            `${prefix}${variant}`,
            useProduction ? isProduction : false
          );
        }
      }

      return null;
    };

    const getDefaultConfig = (data, isProduction, pagePath) => {
      return loadPageConfig(data, pagePath, isProduction);
    };

    fetchAndActivate(remoteConfig)
      .then(() => {
        const data = getAll(remoteConfig);
        let pageConfig;
        const waitlistConfig = JSON.parse(data.New_Plan_Page._value);
        const appConfig = JSON.parse(data.AppConfig._value);
        const useABTestDefaultPage = appConfig.use_ab_test_default_page;
        const useABTestDefaultPageStaging =
          appConfig.use_ab_test_default_page_staging;
        const useABTestDefaultPageDuplicate =
          appConfig.use_ab_test_default_page_duplicate;
        const useABTestDefaultPageDuplicateStaging =
          appConfig.use_ab_test_default_page_duplicate_staging;

        const pageTypes = {
          applyDiscount: {
            condition: applyDiscountPage,
            path: "apply_discount_page",
          },
          blackFriday: {
            condition: holidayPromoPath,
            path: "black_friday_page",
          },
          oldBlackFriday: {
            condition: holidayOldPromoPath,
            path: "old_black_friday_page",
          },
          duplicateOldBlackFriday: {
            condition: holidayOldPromoPathDuplicate,
            path: "old_black_friday_page_duplicate",
          },
          duplicateTwo: {
            condition: holidayOldPromoPathDuplicateTwo,
            path: "old_black_friday_page_duplicate_two",
          },
          duplicateThree: {
            condition: holidayOldPromoPathDuplicateThree,
            path: "old_black_friday_page_duplicate_three",
          },
          preChristmas: {
            condition: holidayPromoThreePath,
            path: "pre_christmas_page_three",
          },
          duplicatePlan: {
            condition: duplicatePlanPagePath,
            path: "duplicate_plan_page",
          },
        };

        const matchingPage = Object.values(pageTypes).find(
          (page) => page.condition
        );

        if (matchingPage) {
          pageConfig = loadPageConfig(data, matchingPage.path, isProduction);

          if (needsWaitlistData(matchingPage.path)) {
            handleWaitlistData(pageConfig, waitlistConfig);
          }
        } else {
          if (duplicatePlanPagePathABtest) {
            const useABTest = isProduction
              ? useABTestDefaultPageDuplicate
              : useABTestDefaultPageDuplicateStaging;

            pageConfig = useABTest
              ? getABTestConfig(
                  data,
                  isProduction,
                  abTestVariant,
                  devModeVariant,
                  "default_plan_page_duplicate"
                )
              : getDefaultConfig(
                  data,
                  isProduction,
                  "default_plan_page_duplicate"
                );

            console.warn(
              `Used ${useABTest ? "AB test" : "default"} config for plan page`
            );
          } else if (defaultPagePath) {
            const useABTest = isProduction
              ? useABTestDefaultPage
              : useABTestDefaultPageStaging;

            pageConfig = useABTest
              ? getABTestConfig(
                  data,
                  isProduction,
                  abTestVariant,
                  devModeVariant,
                  "default_plan_page"
                )
              : getDefaultConfig(data, isProduction, "default_plan_page");

            console.warn(
              `Used ${useABTest ? "AB test" : "default"} config for plan page`
            );
          }
        }

        if (pageConfig) {
          setPageContent(pageConfig);
        }

        setEmailsToDisableAnalyticsEvent(
          appConfig.teamEmailsToDisableAnalyticsEvents
        );
        setApiGeoUrl(appConfig.appConfig.REACT_APP_GEO_API);
        setABtestCampaignName(
          appConfig.appConfig.REACT_APP_A_B_TEST_CAMPAIGN_NAME
        );
        setABtestStartCampaignDate(
          appConfig.appConfig.REACT_APP_START_TEST_CAMPAIGN_DATE
        );
        setAppData(appConfig.appConfig);
        setFbEvent(appConfig.fb_purchase_event);
        setPaymentButtons(appConfig.payment_buttons);
        setRedirectUrl(appConfig.reservePageRedirectURL);
        setEmailProviders(appConfig.emailServices);

        const pageDataConfig = JSON.parse(data.New_Plan_Page._value);
        setComponentsData(pageDataConfig.newPlanPage);
        setNewPageReviews(pageDataConfig.reviews);
        setVideoReviews(pageDataConfig.videoReviews);

        const feedbackConfig = JSON.parse(data.Feedback._value);
        setFeedbackData(feedbackConfig);

        const firebaseTextOptionData = JSON.parse(data.OptionTextConfig._value);
        const stagingFirebaseTextOptionData = JSON.parse(
          data.StagingOptionTextConfig._value
        );

        if (isProduction) {
          const quizName = appConfig.useQuiz;
          const quizDataFirebase = JSON.parse(data[quizName]._value);
          setQuiz(quizDataFirebase);
          setOptionTextData(firebaseTextOptionData);
        } else {
          const quizDataFirebase = JSON.parse(data.Quiz_Staging._value);
          setQuiz(quizDataFirebase);
          setOptionTextData(stagingFirebaseTextOptionData);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsConfigReady(true);
      });
  };

  if (isConfigReady === false) {
    return <Loader />;
  }

  const contextValue = {
    apiGeoUrl,
    abTestCampaignName,
    abTestStartCampaignDate,
    analyticsFirebase,
    paymentButtons,
    quizData,
    appData,
    feedbackData,
    componentsData,
    waitlistPageData,
    reservePageData,
    peopleOnWaitlist,
    newPageReviews,
    newPageVideoReviews,
    fbEvent,
    redirectUrl,
    emailProviders,
    emailsToDisableAnalyticsEvent,
    currentABvariant,
    remotePageContent,
    optionTextData,
    hideDevMode,
    abTestVariant,
  };

  return (
    <RemoteConfigContext.Provider value={contextValue}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
