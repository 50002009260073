import React, { useEffect, useState } from "react";
import { usePageContent } from "../../../../context/PageContext";
import { useUrlContext } from "../../../../context/urlContext";

import Loader from "../../../templates/Loader/Loader";
import DetailsNewH from "./flowDesign/DetailsNewH/DetailsNewH";
import DetailsNewT from "./flowDesign/DetailsNewT/DetailsNewT";

import styles from "./DetailsContainerStyle.module.css";

const DetailsContainer = () => {
  const { productOrder, toggleStatus } = usePageContent();
  const {
    defaultPagePath,
    duplicatePlanPagePath,
    duplicatePlanPagePathABtest,
  } = useUrlContext();
  const [data, setData] = useState({});
  const [orderData, setOrderData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeData = () => {
      try {
        const storageData = JSON.parse(
          localStorage.getItem("MemoryOSCurrentOrder")
        );

        if (productOrder?.order_payment_form_text) {
          setData(productOrder.order_payment_form_text);
          setOrderData(productOrder);
        } else if (storageData) {
          setData(storageData.order_payment_form_text);
          setOrderData(storageData);
        }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, [productOrder]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      {duplicatePlanPagePath && (
        <DetailsNewH
          data={data}
          toggleStatus={toggleStatus}
          orderData={orderData}
        />
      )}
      {duplicatePlanPagePathABtest && (
        <DetailsNewT data={data} orderData={orderData} />
      )}
      {defaultPagePath && <DetailsNewT data={data} orderData={orderData} />}
    </div>
  );
};

export default DetailsContainer;
