import React, { createContext, useContext, useState, useEffect } from "react";

const DeviceContext = createContext();

export const useDevice = () => useContext(DeviceContext);

const detectDevice = () => {
  const userAgent = window.navigator.userAgent;
  return /(iPhone|iPad|iPod)/.test(userAgent);
};

const detectMac = () => {
  const userAgent = window.navigator.userAgent;
  return (
    /(Mac)/.test(userAgent) &&
    !/(iPhone|iPad|iPod)/.test(userAgent) &&
    !/(Android)/.test(userAgent)
  );
};
const detectSafari = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  const isChrome = /chrome|chromium|crios/i.test(userAgent);
  const isSafari = /safari/i.test(userAgent);

  return isSafari && !isChrome;
};

export const DeviceProvider = ({ children }) => {
  const [isIOS, setIsIOS] = useState(detectDevice());
  const [isMac, setIsMac] = useState(detectMac());
  const [isSafari, setIsSafari] = useState(detectSafari());

  useEffect(() => {
    setIsMac(detectMac());
    setIsIOS(detectDevice());
    setIsSafari(detectSafari());
  }, []);

  const contextValue = {
    isMac,
    isIOS,
    isSafari,
  };

  return (
    <DeviceContext.Provider value={contextValue}>
      {children}
    </DeviceContext.Provider>
  );
};
