import React from "react";

import { useTimer } from "../../../../../context/TimerContext";
import { usePageContent } from "../../../../../context/PageContext";

import PaymentButton from "../../PaymentButton/PaymentButton";
import ToggleButton from "../ToggleButton/ToggleButton";
import SpecialOfferButton from "../../SpecialOfferButton/SpecialOfferButton";
import RenderOptions from "./RenderOptions/RenderOptions";

import styles from "./VariantСStyle.module.css";

const VariantС = () => {
  const { showHideElement } = useTimer();

  const {
    showHideToggle,
    toggleStatus,
    showHideDiscountTrialBanner,
    downSaleStatus,
    activateDiscount,
  } = usePageContent();

  return (
    <div className={styles.PlanContainer}>
      <div
        className={styles.OptionsPlanContainer}
        style={{
          height: downSaleStatus
            ? "230px"
            : activateDiscount
            ? "230px"
            : "230px",
        }}
      >
        <RenderOptions />
      </div>
      {showHideToggle && showHideElement && <ToggleButton />}
      {showHideDiscountTrialBanner && toggleStatus ? (
        <SpecialOfferButton />
      ) : (
        <PaymentButton />
      )}
    </div>
  );
};

export default VariantС;
