import { useNavigate } from "react-router-dom";
import { preserveUrlParams } from "../utils/urlParams";
import { useNavigationPath } from "./navigationPath";

export const useNavigateToPaymentForm = () => {
  const navigate = useNavigate();
  const navigatePath = useNavigationPath("payment");

  return () => navigate(preserveUrlParams(navigatePath));
};
