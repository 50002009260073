import React from "react";

import DetailsContainer from "./DetailsContainer/DetailsContainer";

import styles from "./PlanDetails.module.css";

const PlanDetails = () => {
  return (
    <div className={styles.PlanDetailsContainer}>
      <DetailsContainer />
    </div>
  );
};

export default PlanDetails;
