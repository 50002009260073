import React, { useEffect, useState, useMemo, useRef } from "react";
import { usePageConfig } from "../../../context/PageConfigContext";

import { useScroll } from "../../../context/ScrollContext";
import { usePageContent } from "../../../context/PageContext";

import TopComponentVariantB from "./TopComponentVariantB/TopComponentVariantB";
import Header from "./Header/Header";
import StepsContainer from "./StepsContainer/StepsContainer";
import BottomComponent from "./BottomComponent/BottomComponent";
import FooterContainer from "../../Plan/ABspecialOfferPlan/FooterContainer/FooterContainer";

import Loader from "../../templates/Loader/Loader";

import styles from "./DuplicateThankYou.module.css";

const DuplicateThankYou = () => {
  const { findDataComponent } = usePageConfig();
  const { calculateRenewalDate } = usePageContent();

  const [userName, setUserName] = useState("");
  const [planData] = useState(
    JSON.parse(localStorage.getItem("MemoryOSPlanData"))
  );
  const [showHideParagraph, setShowHideParagraph] = useState(true);

  const dataComponent = findDataComponent("RedesignThankYou");
  const [currentPath] = useState(window.location.href);

  const isNewPath = useMemo(() => {
    const targetPath =
      currentPath.includes("/new-h/thankyou") ||
      currentPath.includes("/new-t/thankyou");

    return !currentPath.includes(targetPath);
  }, [currentPath]);

  const thankyouPagePath =
    currentPath.includes("/new-h/thankyou") ||
    currentPath.includes("/new-t/thankyou");

  useEffect(() => {
    setShowHideParagraph(isNewPath);
  }, [isNewPath]);

  useEffect(() => {
    if (thankyouPagePath) {
      const storedData = localStorage.getItem("MemoryOSUserName");
      const userNameData = JSON.parse(localStorage.getItem("MemoryOSUserData"));
      setUserName(storedData !== null ? storedData : userNameData.userName);
      calculateRenewalDate();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thankyouPagePath]);

  const [isLoading, setIsLoading] = useState(true);
  const { scrollRefTop, scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    scrollRefTop.current = PageContainerRef.current;
  }, [scrollRefTop]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      <div ref={PageContainerRef} />
      <Header />
      <TopComponentVariantB
        userName={userName}
        dataComponent={dataComponent}
        planText={planData}
      />
      <StepsContainer
        dataComponent={dataComponent}
        showHideParagraph={showHideParagraph}
      />
      <BottomComponent dataComponent={dataComponent.bottomSection} />
      <FooterContainer />
    </div>
  );
};

export default DuplicateThankYou;
