import React, { useEffect, useRef, useState } from "react";
import { usePageConfig } from "../../../../context/PageConfigContext";

import LogoHP from "../../../templates/LogoHP/LogoHP";

import styles from "./FooterContainer.module.css";

const FooterContainer = () => {
  const { findDataComponent } = usePageConfig();

  const data = findDataComponent("FooterContainer");

  const containerRef = useRef(null);

  const handleEmailClick = () => {
    window.location.href = "mailto:support@memoryos.com";
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+17327439003";
  };

  const [logoStyle, setLogoStyle] = useState({
    width: "130px",
    height: "41px",
  });

  const [widthCont, setWidth] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    return widthCont > 740
      ? setLogoStyle({
          width: "176px",
          height: "56px",
        })
      : setLogoStyle({
          width: "130px",
          height: "41px",
        });
  }, [widthCont]);

  return (
    <div className={styles.FooterContainer} ref={containerRef}>
      <div className={styles.logoPosition}>
        <LogoHP styleData={logoStyle} />
      </div>

      <div className={styles.FooterContainerTextMisson}>{data.missionText}</div>
      <div className={styles.FooterContainerOtherText}>
        <div className={styles.FooterPolicyGroup}>
          <span className={styles.FooterContainerTextLink}>
            <a
              href="https://memoryos.com/privacy-policy"
              className={styles.FooterContainerText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </span>
          <span className={styles.FooterContainerTextLink}>
            <a
              href="https://memoryos.com/terms"
              className={styles.FooterContainerText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </span>
          <span className={styles.FooterContainerTextLink}>
            {" "}
            <a
              href="https://memoryos.com/terms"
              className={styles.FooterContainerText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Subscription Policy
            </a>
          </span>
        </div>
        <div className={styles.FooterContactGroup}>
          <span className={styles.TelMail} onClick={handleEmailClick}>
            support@memoryos.com
          </span>
          <span className={styles.TelMail} onClick={handlePhoneClick}>
            +1 (732) 743 9003
          </span>
        </div>
      </div>
      <div className={styles.hrLine} />
      <div className={styles.FooterCopyright}>
        <span className={styles.FooterCopyrightText}>
          {data.copyrightText[0]} {new Date().getFullYear()}{" "}
          {data.copyrightText[1]}
        </span>
      </div>
    </div>
  );
};

export default FooterContainer;
